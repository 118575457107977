<template>
    <div class="weihu">
        
        <div class="img"><img src="@/assets/img/404.png" alt=""></div>

        <div class="text">系统维护中。。。</div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
import mitts from "@/bus";
onMounted(() => {
  setTimeout(() => {
    mitts.emit("closeLoading");
  }, 500)
})
</script>

<style lang="less" scoped>
.weihu {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.img {
    width: 90%;
    img {
        width: 100%;
        display: block;
    }
}

.text{
    font-size: 36px;
    font-weight: 600;
}
</style>